.mainJumbo {
  height: 100vh;
  width: 100%;
  background-image: url("./Images/Background.jpg");
  background-position: center;
  background-size: cover;
  left: 0;
}

.mainJumbo.jumbotron.jumbotron-fluid {
  margin-bottom: 0;
}
