img {
  width: 90%;
  margin-bottom: 1%;
}

.about-row.row {
  margin-top: 7%;
  padding-left: 5%;
  padding-right: 5%;
}

.col-sm-7.card.bg-light.border-dark {
  font-family: Arial, Helvetica, sans-serif;
}

h4 {
  padding-top: 1.5%;
  text-align: center;
}

h5 {
  text-align: center;
}

.aboutMe {
  text-indent: 1em;
}