.brand {
  width: 18%;
}

.nav {
  font-weight: 500;
}

.link {
  color: DodgerBlue !important;
  text-decoration: underline;
}

.dropdown-toggle.nav-link {
  color: white !important;
}

.navbar.navbar-expand-md.navbar-dark.fixed-top {
  background: rgba(105, 105, 105, 0.5);
}

.link-icon {
  width: 12px;
}